export const theme = {
  backgroundColor: 'hsla(240, 11%, 96%, 1)',
  themeColorPrimary: 'hsla(242, 100%, 58%, 1)',
  themeColorSecondary: 'hsla(336, 100%, 52%, 1)',
  primaryColorLight: 'hsl(0, 0%, 100%)',
  secondaryColorLight: 'hsla(0, 0%, 87%, 1)',
  primaryColorDark: 'hsl(0, 0%, 0%)',
  secondaryColorDark: 'hsl(0, 0%, 51%)',

  fontSize: {
    xl: '3rem',
    l: '3rem',
    m: '2rem',
    s: '1.6rem'
  }
};
